// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "BlogsMain-module--aboutMainCircleBlurBlue--9e774";
export var aboutMainCircleBlurGreen = "BlogsMain-module--aboutMainCircleBlurGreen--3dad7";
export var backgroundCircles = "BlogsMain-module--backgroundCircles--0c68d";
export var backgroundCirclesBlogArticles = "BlogsMain-module--backgroundCirclesBlogArticles--7980b";
export var blogImageContainer = "BlogsMain-module--blogImageContainer--4b95b";
export var blogMainContainer = "BlogsMain-module--blogMainContainer--6f465";
export var blogMainContainerWrapper = "BlogsMain-module--blogMainContainerWrapper--135d6";
export var blogMainTags = "BlogsMain-module--blogMainTags--9b40d";
export var blogModalNav = "BlogsMain-module--blogModalNav--e3fc3";
export var blogModalWrapper = "BlogsMain-module--blogModalWrapper--210bd";
export var blogTag = "BlogsMain-module--blogTag--9a06c";
export var blogTagsWrapper = "BlogsMain-module--blogTagsWrapper--bbf52";
export var blogsCircleBlueRight = "BlogsMain-module--blogsCircleBlueRight--773d8";
export var blogsMainArticles = "BlogsMain-module--blogsMainArticles--9b8e1";
export var blogsWrapper = "BlogsMain-module--blogsWrapper--69b2f";
export var contactOutlineCircle = "BlogsMain-module--contactOutlineCircle--43c4f";
export var filterActionButton = "BlogsMain-module--filterActionButton--067fc";
export var paginationWrapper = "BlogsMain-module--paginationWrapper--5c4d6";
export var stickyContainerLeft = "BlogsMain-module--stickyContainerLeft--c9ecb";