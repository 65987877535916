import React, { FC } from "react";
import { Link } from "gatsby";
import { BLOG_ACTIVE_COLOR, NavTag } from "../BlogsMain.helper";

type Props = {
  wrapperClass: string;
  tagsArray: NavTag[];
  basePathForUrl: string;
};

const BlogsMainTags: FC<Props> = ({
  tagsArray,
  wrapperClass,
  basePathForUrl,
}) => {
  return (
    <>
      {tagsArray &&
        tagsArray.map((tag: NavTag, index: number) => {
          return (
            <div key={tag.title + index} className={wrapperClass}>
              <Link
                activeStyle={{
                  color: BLOG_ACTIVE_COLOR,
                  borderBottom: `1px solid ${BLOG_ACTIVE_COLOR}`,
                }}
                to={`${basePathForUrl}/${tag.title.toLowerCase()}`}
              >
                {tag.title}
              </Link>
            </div>
          );
        })}
    </>
  );
};

export default BlogsMainTags;
