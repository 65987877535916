import { Link } from "gatsby";
import React, { FC } from "react";
import whitelinePagination from "../../../images/whitelinePagination.svg";
import {
  BLOG_PAGINATION_COLOR_GREY,
  BLOG_PAGINATION_SELECTED_COLOR,
} from "./BlogsMain.helper";
import * as styles from "./BlogsMain.module.scss";

type Props = {
  pageNumber: number;
  currentPage: number;
  basePathForUrl: string;
};

const BlogsPagination: FC<Props> = ({
  pageNumber,
  currentPage,
  basePathForUrl,
}) => {
  return pageNumber > 1 ? (
    <div className={styles.paginationWrapper}>
      {Array.from({ length: pageNumber }, (_, i) => (
        <Link
          style={{
            color:
              currentPage === i + 1
                ? BLOG_PAGINATION_SELECTED_COLOR
                : BLOG_PAGINATION_COLOR_GREY,
          }}
          key={`pagination-number${i + 1}`}
          to={`${basePathForUrl.toLowerCase()}${i === 0 ? "" : `/${i + 1}`}`}
        >
          {i < 9 ? `0${i + 1}` : `${i + 1}`}
          {i + 1 < pageNumber && currentPage === i + 1 ? (
            <>
              <img src={whitelinePagination} alt="white line" />
            </>
          ) : null}
        </Link>
      ))}
    </div>
  ) : null;
};

export default BlogsPagination;
