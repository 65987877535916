import { useEffect } from "react";

export const UseTagsFilter = (blogCategory: any, blogs: any, allTags: any) => {
  let AllTags: any = [];
  let uniqueItems: any = [];
  let selectedTags: any = [];

  useEffect(() => {
    blogs.length &&
      blogs.map(({ node }: any) => {
        node.blogType.forEach((x: any) => {
          if (x.title === blogCategory) {
            AllTags.push(node.tags);
          }
        });
        return {};
      });
  }, [blogs]);

  useEffect(() => {
    AllTags.length &&
      AllTags.flat().map((x: any) => {
        if (!uniqueItems.includes(x.title) && x.title !== "DEUTSCH") {
          uniqueItems.push(x.title);
        }
        return {};
      });
  }, [AllTags]);

  useEffect(() => {
    if (uniqueItems.length) {
      allTags.map((x: any) => {
        if (uniqueItems.includes(x.title)) {
          selectedTags.push(x);
        }
        return {};
      });
    }
  }, [uniqueItems]);

  return selectedTags;
};
