import React, { FC, useState, useEffect } from "react";
import * as styles from "./BlogsMain.module.scss";
import { Link } from "gatsby";
import Sticky from "react-sticky-el";
import BlogArticleImage from "../../HomeUiComponents/BlogMain/BlogArticle/BlogArticleImage";
import BlogArticle from "../../HomeUiComponents/BlogMain/BlogArticle/BlogArticle";
import CircleBlur from "../../Shared/CircleOutlineAndBlur/CircleBlur";
import CircleOutline from "../../Shared/CircleOutlineAndBlur/CircleOutline";
import BlogsPagination from "./BlogsPagination";
import filter from "../../../images/filter.svg";
import { BLOG_ACTIVE_COLOR, checkNameOfBlog } from "./BlogsMain.helper";
import { UseWindowSize } from "../../hooks/UseWindowSize";
import MobileModal from "../../Shared/MobileModal/MobileModal";
import BlogsMainTags from "./BlogsMainHelperComponents/BlogsMainTags";
import InnerWrapper from "../../Shared/InnerWrapper/InnerWrapper";
import CircleGreenBlur from "../../Shared/CircleOutlineAndBlur/Circlegreenblur";

type Props = {
  data: any;
  blogName: string;
  basePathForUrl?: string;
  tags?: any;
  numberPages?: any;
  currentPage?: any;
  basePathForPaginations?: string;
};

const BlogsMain: FC<Props> = ({
  data,
  tags,
  basePathForUrl,
  numberPages,
  currentPage,
  basePathForPaginations,
}) => {
  const [tagsFromBlog] = useState(tags);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [showBlogImage, setShowBlogImage] = useState({
    image: data[0]?.node._rawMainImage || "",
    pathToBlog: basePathForUrl || "",
    title: data[0]?.node.title || "",
    alt: data[0]?.node.alt || "",
  });

  useEffect(() => {
    setShowBlogImage({
      image: data[0]?.node._rawMainImage,
      pathToBlog: basePathForUrl,
      title: data[0]?.node.title || "",
      alt: data[0]?.node.alt || "",
    });
  }, [data]);
  const { width } = UseWindowSize();

  return (
    <div className={styles.blogMainContainerWrapper}>
      <InnerWrapper>
        <div>
          <div className={styles.blogMainContainer}>
            <h1>
              <span>.</span>
              {checkNameOfBlog(basePathForUrl)}
            </h1>
            <p>
              {checkNameOfBlog(basePathForUrl) === "Tech blog"
                ? "Keep up with our latest cloud technology articles, news, and tutorials."
                : checkNameOfBlog(basePathForUrl) === "GDN News"
                ? "Follow our journey and be the first to know about our exciting partnerships , plans and community news."
                : "Stay up to date with the latest cloud insights and all the related news."}
            </p>
            <div className={styles.backgroundCircles}>
              <CircleOutline
                isBlurVisible={false}
                initialScale={0.3}
                animateScale={1}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.contactOutlineCircle}
              />
              <CircleGreenBlur
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.aboutMainCircleBlurGreen}
              />

              <CircleBlur
                initialOpacity={0}
                animateOpacity={0.1}
                transitionType={"easeIn"}
                transitionDelay={0.2}
                transitionDuration={1}
                circleStyleClass={styles.aboutMainCircleBlurBlue}
              />
            </div>
          </div>
          <div className={styles.blogMainTags}>
            {width < 771 ? (
              <>
                <div
                  className={styles.filterActionButton}
                  onClick={() => setIsFilterOpen(true)}
                >
                  <span>filter</span>
                  <img src={filter} alt="filter" />
                </div>

                <div
                  style={{
                    visibility: isFilterOpen ? "visible" : "hidden",
                  }}
                  className={styles.blogModalWrapper}
                >
                  <MobileModal
                    setIsModalHidden={setIsFilterOpen}
                    modalContent={"blog"}
                    basePathForUrl={basePathForUrl}
                    blogTagClass={styles.blogTag}
                    blogWrapperClass={styles.blogTagsWrapper}
                    tagsFromBlog={tagsFromBlog}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={styles.blogTag}>
                  <Link
                    activeStyle={{
                      color: BLOG_ACTIVE_COLOR,
                      borderBottom: `1px solid ${BLOG_ACTIVE_COLOR}`,
                    }}
                    to={basePathForUrl}
                  >
                    All
                  </Link>
                </div>
                <BlogsMainTags
                  tagsArray={tagsFromBlog}
                  wrapperClass={styles.blogTag}
                  basePathForUrl={basePathForUrl}
                />
              </>
            )}
          </div>

          {!isFilterOpen && (
            <div className={styles.blogsMainArticles}>
              <div className={styles.stickyContainerLeft} id="stickyContainer">
                <Sticky boundaryElement="#stickyContainer">
                  <BlogArticleImage
                    rawImage={showBlogImage?.image}
                    pathToBlog={showBlogImage?.pathToBlog}
                    title={showBlogImage?.title}
                    imageClassName={styles.blogImageContainer}
                    imageName={"imagename"}
                    alt={showBlogImage?.alt}
                  />
                </Sticky>
              </div>
              <div className={styles.blogsWrapper}>
                {data?.map(({ node }: any, index: number) => (
                  <BlogArticle
                    title={node.title}
                    description={node.subtitle}
                    key={index}
                    pic={node._rawMainImage}
                    onBlogHover={setShowBlogImage}
                    dateBlog={node.publishedAt}
                    pathToBlog={basePathForUrl}
                    alt={node?.alt}
                  />
                ))}
                <BlogsPagination
                  pageNumber={numberPages}
                  currentPage={currentPage}
                  basePathForUrl={basePathForPaginations || basePathForUrl}
                />
              </div>
              <div className={styles.backgroundCirclesBlogArticles}>
                <CircleBlur
                  initialOpacity={0}
                  animateOpacity={0.1}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.aboutMainCircleBlurGreen}
                />

                <CircleBlur
                  initialOpacity={0}
                  animateOpacity={0.1}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.aboutMainCircleBlurBlue}
                />

                <CircleBlur
                  initialOpacity={0}
                  animateOpacity={0.1}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.blogsCircleBlueRight}
                />
              </div>
            </div>
          )}
        </div>
      </InnerWrapper>
    </div>
  );
};

export default BlogsMain;
